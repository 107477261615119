import { useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { ref } from 'vue'

import ProductVariantService from '@/services/product_variant_service'
import ShoppingCartService from '@/services/shopping_cart_service'
import { ShoppingCartLineItem } from '@/types'

const STORE_NAME = 'shoppingCart'

export const useShoppingCart = defineStore(STORE_NAME, () => {
  const state = ref(
    useStorage(STORE_NAME, {
      lineItems: [],
      shipping: '',
      quoting: false
    } as any)
  )

  function setLineItems(updatedLineItems: ShoppingCartLineItem[]) {
    state.value.lineItems = updatedLineItems
    quote()
  }

  function checkPreviews(lineItemsToCheck: ShoppingCartLineItem[] = state.value.lineItems) {
    lineItemsToCheck.forEach(async (lineItem) => {
      let previewUrl = (await ProductVariantService.preview(lineItem.product_variant_id)).preview_url

      if (previewUrl) {
        lineItem.preview = previewUrl
      }
    })

    const itemsWithoutPreview = state.value.lineItems.filter((lineItem: ShoppingCartLineItem) => {
      return !lineItem.preview
    })

    if (itemsWithoutPreview.length > 0) {
      setTimeout(() => checkPreviews(itemsWithoutPreview), 3000)
    }
  }

  function addLineItem(lineItem: ShoppingCartLineItem) {
    state.value.lineItems.push(lineItem)
    quote()
  }

  function addLineItems(lineItemsToAdd: ShoppingCartLineItem[]) {
    lineItemsToAdd.forEach((lineItemToAdd) => {
      const existingLineItem = state.value.lineItems.find(
        (lineItem) => lineItem.product_variant_id === lineItemToAdd.product_variant_id
      )
      if (existingLineItem) {
        existingLineItem.quantity += lineItemToAdd.quantity
      } else {
        state.value.lineItems.push(lineItemToAdd)
      }
    })
    quote()
  }

  function removeLineItems(lineItemsToRemove: ShoppingCartLineItem[]) {
    state.value.lineItems = state.value.lineItems.filter(
      (lineItem: ShoppingCartLineItem) =>
        lineItemsToRemove
          .map((itemToRemove) => itemToRemove.product_variant_id)
          .includes(lineItem.product_variant_id) == false
    )
    quote()
  }

  function clear() {
    state.value.lineItems = []
  }

  function updateQuantity(lineItem: ShoppingCartLineItem, quantity: number) {
    if (quantity <= 0) {
      removeLineItems([lineItem])
    } else {
      const existingLineItem = state.value.lineItems.find(
        (existingLineItem) => existingLineItem.product_variant_id === lineItem.product_variant_id
      )
      if (existingLineItem) {
        existingLineItem.quantity = quantity
      }
      quote()
    }
  }

  async function quote() {
    state.value.quoting = true
    const response = await ShoppingCartService.quote(state.value.lineItems)
    state.value.lineItems = response.line_items
    state.value.shipping = response.shipping
    state.value.quoting = false
  }

  function lineItems(): ShoppingCartLineItem[] {
    return state.value.lineItems
  }

  function totalQuantity(): number {
    return state.value.lineItems.reduce((total, lineItem) => total + lineItem.quantity, 0)
  }

  function shipping() {
    return state.value.shipping
  }

  function quoting(): boolean {
    return state.value.quoting
  }

  return {
    lineItems,
    totalQuantity,
    shipping,
    quoting,
    setLineItems,
    addLineItem,
    addLineItems,
    removeLineItems,
    checkPreviews,
    clear,
    quote,
    updateQuantity
  }
})
