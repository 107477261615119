import { Configuration } from '@/stores/admin/configuration'
import { PaginatedCollection, Style, StyleCategory, StyleVariant } from '@/types'

import ApiService from './api_service'

export default {
  async index(query = {}): Promise<PaginatedCollection<Style>> {
    const response = await ApiService.get('/admin/styles', { params: query })
    return response.data
  },

  async get(id: number | string): Promise<Style> {
    const response = await ApiService.get(`/admin/styles/${id}`)
    return response.data
  },

  async update(id: number | string, params: Style): Promise<Style> {
    const response = await ApiService.put(`/admin/styles/${id}`, { style: params })
    return response.data
  },

  async create(params: Style): Promise<Style> {
    const response = await ApiService.post('/admin/styles', { style: params })
    return response.data
  },

  async variants(id: number | string): Promise<StyleVariant[]> {
    const response = await ApiService.get(`/admin/styles/${id}/variants`)
    return response.data
  },

  async categories(): Promise<{ categories: StyleCategory[]; offering: any }> {
    const response = await ApiService.get(`/shopify-app/${Configuration().shopifyShopId}/styles/categories`)
    return response.data
  },

  async catalogData(category: string, quality_level?: string): Promise<{ styles: Style[]; variants: StyleVariant[] }> {
    const response = await ApiService.get(
      `/shopify-app/${Configuration().shopifyShopId}/styles/categories/${category}/${quality_level || ''}`
    )
    return response.data
  },

  async publicCategories(): Promise<{ categories: StyleCategory[]; offering: any }> {
    const response = await ApiService.get(`/styles/categories`)
    return response.data
  },

  async publicCatalogData(
    category: string,
    quality_level?: string
  ): Promise<{ styles: Style[]; variants: StyleVariant[] }> {
    const response = await ApiService.get(`/styles/categories/${category}/${quality_level || ''}`)
    return response.data
  }
}
