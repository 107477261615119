import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useCookies } from 'vue3-cookies'

import { useShoppingCart } from '@/stores/shopping_cart'
import { Session, ShopifyShop, User } from '@/types'

export const UserSession = defineStore('userSession', () => {
  const { cookies } = useCookies()
  const shoppingCart = useShoppingCart()
  const currentUser = ref({} as User)
  const currentShopifyShop = ref({} as ShopifyShop)
  const shopifyHost = ref('')
  const currentCurrency = ref(
    cookies.get('currency') || ({ code: 'eur', symbol: '€', name: 'Euro', symbol_position: 'before' } as any)
  )

  function setShopifyHost(host: string) {
    shopifyHost.value = host
  }

  function setCurrentSession(session: Session) {
    currentUser.value = session.user
  }

  function setCurrentUser(user: User) {
    currentUser.value = user
  }

  function setCurrentCurrency(currency: any) {
    currentCurrency.value = currency
    cookies.set('currency', currency)
    shoppingCart.quote()
  }

  function setCurrentLocale(locale: string) {
    cookies.set('locale', locale)
  }

  function setCurrentShopifyShop(shopifyShop: ShopifyShop) {
    currentShopifyShop.value = shopifyShop
  }

  function initializeCurrency(_country: string) {
    if (cookies.get('currency')) {
      return
    }

    currentCurrency.value = { code: 'eur', symbol: '€', name: 'Euro', symbol_position: 'before' }
  }

  return {
    currentUser,
    currentCurrency,
    shopifyHost,
    currentShopifyShop,
    initializeCurrency,
    setCurrentSession,
    setCurrentCurrency,
    setCurrentLocale,
    setCurrentUser,
    setCurrentShopifyShop,
    setShopifyHost
  }
})
