<template>
  <main class="grid min-h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
    <div class="text-center">
      <p class="text-base font-semibold text-indigo-600">404</p>
      <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{{ $t('not_found.title') }}</h1>
      <p class="mt-6 text-base leading-7 text-gray-600">{{ $t('not_found.subtitle') }}</p>
      <div class="mt-10 flex items-center justify-center gap-x-6">
        <a
          class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          href="https://printplus.app/shopify-app"
        >
          {{ $t('not_found.back_to_home') }}
        </a>
        <a
          class="text-sm font-semibold text-gray-900"
          href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#115;&#117;&#112;&#112;&#111;&#114;&#116;&#64;&#112;&#114;&#105;&#110;&#116;&#112;&#108;&#117;&#115;&#46;&#97;&#112;&#112;"
        >
          {{ $t('not_found.contact_us') }}
          <span aria-hidden="true">&rarr;</span>
        </a>
      </div>
    </div>
  </main>
</template>

<script lang="ts" setup>
import { useSeoMeta } from '@unhead/vue'
import { useI18n } from 'vue-i18n'

const i18n = useI18n()

useSeoMeta({
  title: i18n.t('not_found.seo.title'),
  description: i18n.t('not_found.seo.description'),
  ogDescription: i18n.t('not_found.seo.description'),
  ogTitle: i18n.t('not_found.seo.title'),
  ogImage: 'https://img.printplus.app/misc/og_image.jpg',
  ogSiteName: 'PrintPlus',
  ogType: 'website',
  ogLocale: i18n.locale
})
</script>
