import Axios from 'axios'

export default {
  async get() {
    const response = await Axios.create({
      baseURL: 'https://api.printplus.app',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }).get(`/configuration`)
    return response.data
  }
}
