export enum RedirectTarget {
  App,
  Admin,
  Remote
}

export function useShopifyAppBridge() {
  const AppBridge = window['app-bridge']

  if (!AppBridge) {
    return
  }

  function sessionToken(host: string): Promise<string> {
    const shopifyApp = initializeShopifyApp(host)
    return AppBridge.utilities.getSessionToken(shopifyApp)
  }

  function redirect(
    host: string,
    url: string,
    target: RedirectTarget = RedirectTarget.App,
    newWindow: boolean = false
  ) {
    const shopifyApp = initializeShopifyApp(host)
    const redirect = AppBridge.actions.Redirect.create(shopifyApp)
    if (target === RedirectTarget.Remote) {
      return redirect.dispatch(getRedirectActionTarget(target), { url: url, newContext: newWindow })
    } else {
      return redirect.dispatch(getRedirectActionTarget(target), { path: url, newContext: newWindow })
    }
  }

  // private functions

  function initializeShopifyApp(host: string) {
    const bridgeConfig = {
      apiKey: import.meta.env.VITE_SHOPIFY_CLIENT_ID,
      host: host
    }
    const shopifyApp = AppBridge.createApp(bridgeConfig)
    return shopifyApp
  }

  function getRedirectActionTarget(target: RedirectTarget) {
    switch (target) {
      case RedirectTarget.App:
        return AppBridge.actions.Redirect.Action.APP
      case RedirectTarget.Admin:
        return AppBridge.actions.Redirect.Action.ADMIN_PATH
      case RedirectTarget.Remote:
        return AppBridge.actions.Redirect.Action.REMOTE
    }
  }

  return { sessionToken, redirect }
}
