import { defineStore } from 'pinia'

import ConfigurationService from '@/services/configuration_service'
import { UserSession } from '@/stores/user_session'

export const Configuration = defineStore('configuration', {
  state: () => {
    // TODO: initialize if needed?
    return {
      countries: [],
      shippingCountries: [],
      shippingPrices: [],
      currencyExchangeRates: {},
      styleCategories: [],
      styleQualityLevels: [],
      styleGenders: [],
      designAreas: [],
      shopifyShopId: null,
      ipCountry: null
    }
  },

  actions: {
    async initialize() {
      const response = await ConfigurationService.get()
      this.countries = response.data.countries
      this.shippingCountries = response.data.shipping_countries
      this.shippingPrices = response.data.shipping_prices
      this.currencyExchangeRates = response.data.currency_exchange_rates || {}
      this.styleCategories = response.data.style_categories
      this.styleQualityLevels = response.data.style_quality_levels
      this.styleGenders = response.data.style_genders
      this.designAreas = response.data.design_areas
      this.ipCountry = response.data.ip_country

      this.designAreasOrder = {}
      this.designAreas.forEach((da, index) => {
        this.designAreasOrder[da] = index
      })

      UserSession().initializeCurrency(this.ipCountry)
    },

    setShopifyShopId(shopifyShopId: string) {
      this.shopifyShopId = shopifyShopId
    },

    designAreaNaturalOrder(a, b) {
      if (!this.designAreasOrder) {
        return 0
      }
      return this.designAreasOrder[a.location] - this.designAreasOrder[b.location]
    }
  }
})
