import { UserSession } from '@/stores/user_session'
import { ShoppingCartLineItem } from '@/types'

import ApiService from './api_service'

export default {
  async quote(lineItems: ShoppingCartLineItem[]) {
    const response = await ApiService.post(
      `/cart/quote`,
      {
        line_items: lineItems,
        currency: UserSession().currentCurrency.code
      },
      { withCredentials: true }
    )
    return response.data
  },

  async checkout(lineItems: ShoppingCartLineItem[], locale: string = 'en') {
    const response = await ApiService.post(`/cart/checkout`, {
      line_items: lineItems,
      locale: locale,
      currency: UserSession().currentCurrency.code
    })
    return response.data
  }
}
