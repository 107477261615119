import { Configuration } from '@/stores/admin/configuration'
import { PaginatedCollection, Product } from '@/types'

import ApiService from './api_service'

export type ProductPreview = {
  title: string
  variants: Array<{
    name: string
    hex_color: string
    previews: Array<{
      location: string
      image: string
    }>
  }>
}

export default {
  // Application App
  async publicGet(id: number | string): Promise<Product> {
    const response = await ApiService.get(`/products/${id}`)
    return response.data
  },

  async publicCreate(product: Product): Promise<Product> {
    const response = await ApiService.post(`/products`, {
      product: product
    })
    return response.data
  },

  async publicUpdate(id: number, product: Product): Promise<Product> {
    const response = await ApiService.post(`/products/${id}`, {
      product: product
    })
    return response.data
  },

  async addVariant(productId: number, variantIds: (number | string)[]): Promise<Product> {
    const response = await ApiService.post(`/products/${productId}/add-variants`, {
      variant_ids: variantIds
    })
    return response.data
  },

  async preview(id: number | string): Promise<ProductPreview> {
    const response = await ApiService.get(`/products/${id}/preview`)
    return response.data
  },

  // Admin App

  async all(query = {}): Promise<PaginatedCollection<Product>> {
    const response = await ApiService.get(`/admin/products`, { params: query })
    return response.data
  },

  async delete(id: number): Promise<void> {
    await ApiService.delete(`/admin/products/${id}`)
  },

  // Shopify App

  async index(): Promise<Product[]> {
    const response = await ApiService.get(`/shopify-app/${Configuration().shopifyShopId}/products`)
    return response.data
  },

  async get(id: number | string): Promise<Product> {
    const response = await ApiService.get(`/shopify-app/${Configuration().shopifyShopId}/products/${id}`)
    return response.data
  },

  async create(product: Product): Promise<Product> {
    const response = await ApiService.post(`/shopify-app/${Configuration().shopifyShopId}/products`, {
      product: product
    })
    return response.data
  },

  async update(id: number, product: Product): Promise<Product> {
    const response = await ApiService.put(`/shopify-app/${Configuration().shopifyShopId}/products/${id}`, {
      product: product
    })
    return response.data
  },

  async deleteDraft(id: number): Promise<void> {
    const response = await ApiService.delete(`/shopify-app/${Configuration().shopifyShopId}/products/${id}`)
    return response.data
  },

  async availableVariants(id: number | string): Promise<any> {
    const response = await ApiService.get(
      `/shopify-app/${Configuration().shopifyShopId}/products/${id}/available-variants`
    )
    return response.data
  },

  async addVariants(id: number | string, variants: any): Promise<Product> {
    const response = await ApiService.post(
      `/shopify-app/${Configuration().shopifyShopId}/products/${id}/add-variants`,
      {
        variants: variants
      }
    )
    return response.data
  }
}
