import Axios from 'axios'

import { useShopifyAppBridge } from '@/plugins/shopify_app_bridge'
import { UserSession } from '@/stores/user_session'

const ApiService = Axios.create({
  baseURL: import.meta.env.VITE_API_URL
})

const appBridge = useShopifyAppBridge()

ApiService.defaults.headers.common['X-CSRF-Token'] = document
  .querySelector('meta[name="csrf-token"]')
  .getAttribute('content')

ApiService.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

ApiService.interceptors.request.use(
  async (config) => {
    const host = UserSession().shopifyHost

    if (!appBridge) return config
    const sessionToken = await appBridge.sessionToken(host)

    config.headers = {
      'sessionToken': sessionToken
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default ApiService
